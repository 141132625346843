/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import Button from 'react-bootstrap/Button';

import TableColumnSearch from '../../TableColumnSearch';

import UserTable from '../UserTable';
import ViewUser from '../ViewUser';

const UserList = ({ users }) => {
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const data = useMemo(() => users, [users]);

  const handleViewUserShow = () => {
    setShow((prevState) => !prevState);
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        id: 'user',
        Cell: ({ row }) => (
          <Avatar round="5px" size="25px" name={row.original.username} />
        ),
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'First name',
        accessor: 'firstName',
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role.name',
      },
      {
        id: 'view',
        Cell: ({ row }) => (
          <Button
            size="sm"
            onClick={() => {
              setShow((prevState) => !prevState);
              setId(row.original.id);
            }}
          >
            View / Edit
          </Button>
        ),
      },
    ],
    []
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <UserTable columns={columns} data={data} />
      <ViewUser
        show={show}
        onHide={handleViewUserShow}
        user={id ? data.find((element) => element.id === id) : {}}
      />
    </>
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      username: PropTypes.string.isRequired,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      password: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ).isRequired,
};

export default UserList;
