import { gql } from '@apollo/client';

const PROJECTS = gql`
  query getProjects {
    getProjects {
      id
      client {
        id
        name
        country
        poNumber
        city
        street
        streetNumber
        createdAt
        updatedAt
      }
      invoicePaymentTerms
      billingInstructions
      billingContact {
        id
        name
        email
        phone
        createdAt
        updatedAt
      }
      name
      engagementName
      engagementType
      billable
      projectManager {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      engagementManager {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      utilizationEligible
      goToMarket
      businessUnit
      serviceArea
      projectCostcenter
      salesLead {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      startDate
      endDate
      budget
      travelAndExpenseApprover {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      enableAdditionalTimeTypesNotBillable
      enableAdditionalTimeTypesOverTimePaid
      enableAdditionalTimeTypesOverTimeNoPay
      enableAdditionalTimeTypesOnCall
      enableExpenses
      expensePolicy
      psaMsaExecuted
      ndaExecuted
      sowCoCrExecuted
      resources {
        id
        user {
          id
          username
          firstName
          lastName
          email
          createdAt
          updatedAt
        }
        role
        billRate
        startDate
        endDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export default PROJECTS;
