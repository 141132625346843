import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import LOGIN_USER from '../../../mutations/loginUser';

import logo from '../../../images/logo-transparent.png';

import './index.css';

const LoginForm = ({ children }) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [loginUser, { data: jwtToken, error }] = useMutation(LOGIN_USER, {
    onCompleted: (data) => {
      localStorage.setItem('accessToken', data.loginUser.accessToken);
      localStorage.setItem('refreshToken', data.loginUser.refreshToken);

      history.push('/projects');
    },
    onError() {
      // eslint-disable-next-line no-console
      console.log('Wrong username or password!');
    },
  });

  return (
    <div className="login-form">
      <img
        src={logo}
        title="psa-team"
        alt=""
        style={{
          display: 'block',
          marginRight: 'auto',
          marginLeft: 'auto',
          paddingTop: '15px',
        }}
      />
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          loginUser({
            variables: {
              loginUserInput: {
                username: values.username,
                password: values.password,
              },
            },
          });

          setSubmitting(false);
        }}
      >
        {/* eslint-disable-next-line no-unused-vars */}
        {({ values, isSubmitting }) => (
          <Form
            style={{
              top: '62%',
              left: '50%',
              width: '70%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Field name="username">
              {({ field }) => (
                <FormGroup className="mb-3" controlId="username">
                  <FormLabel>Username:</FormLabel>
                  <FormControl
                    required
                    type="text"
                    placeholder="Enter username"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormGroup>
              )}
            </Field>
            {error &&
              JSON.stringify(error.message).replace(/"/g, '') ===
                'Wrong username!' && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '-10px',
                    marginLeft: '5px',
                  }}
                >
                  {JSON.stringify(error.message).replace(/"/g, '')}
                </p>
              )}
            <Field name="password">
              {({ field }) => (
                <FormGroup className="mb-3" controlId="password">
                  <FormLabel>Password:</FormLabel>
                  <FormControl
                    required
                    type="password"
                    placeholder="Enter password"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormGroup>
              )}
            </Field>
            {error &&
              JSON.stringify(error.message).replace(/"/g, '') ===
                'Wrong password!' && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '-10px',
                    marginLeft: '5px',
                  }}
                >
                  {JSON.stringify(error.message).replace(/"/g, '')}
                </p>
              )}

            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Please wait...' : 'Log In'}
            </Button>
            {children}
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoginForm.propTypes = {
  children: PropTypes.node,
};

LoginForm.defaultProps = {
  children: null,
};

export default LoginForm;
