import { gql } from '@apollo/client';

const UPDATE_USER = gql`
  mutation UpdateUserMutation($updateUserInput2: UpdateUserInput!) {
    updateUser(input: $updateUserInput2) {
      id
      password
      username
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

export default UPDATE_USER;
