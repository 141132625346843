import { gql } from '@apollo/client';

const CLIENTS = gql`
  query getClients {
    getClients {
      id
      name
      country
      poNumber
      city
      street
      streetNumber
      createdAt
      updatedAt
    }
  }
`;

export default CLIENTS;
