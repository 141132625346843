import React from 'react';

import PropTypes from 'prop-types';

import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

const ProjectFromGroup = ({ client, defaultProjectValues }) => (
  <>
    <FormGroup className="mb-3" controlId="name">
      <FormLabel>Client name:</FormLabel>
      <FormControl
        disabled
        type="text"
        placeholder="Client name"
        defaultValue={client.name || defaultProjectValues.client.name}
      />
    </FormGroup>
    <FormGroup className="mb-3" controlId="address">
      <FormLabel>Client address:</FormLabel>
      <FormControl
        disabled
        type="text"
        placeholder="Address not specified"
        defaultValue={`${
          (client.country ? `${client.country},` : '') ||
          defaultProjectValues.client.country
        } ${
          (client.city ? `${client.city},` : '') ||
          defaultProjectValues.client.city
        } ${
          (client.street ? client.street : '') ||
          defaultProjectValues.client.street
        } ${
          (client.streetNumber ? client.streetNumber : '') ||
          defaultProjectValues.client.streetNumber
        }`}
      />
    </FormGroup>
    <FormGroup className="mb-3" controlId="poNum">
      <FormLabel>Client PO number:</FormLabel>
      <FormControl
        disabled
        type="number"
        placeholder="PO number not specified"
        defaultValue={client.poNumber || defaultProjectValues.client.poNumber}
      />
    </FormGroup>
  </>
);

ProjectFromGroup.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    streetNumber: PropTypes.number,
    poNumber: PropTypes.number,
    country: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
  }).isRequired,
  defaultProjectValues: PropTypes.shape(PropTypes.object),
};

ProjectFromGroup.defaultProps = {
  defaultProjectValues: undefined || {},
};

export default ProjectFromGroup;
