import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

function TableColumnSearch({ column: { filterValue, setFilter } }) {
  return (
    <input
      style={{
        border: '1px solid lightgray',
        borderRadius: '5px',
        padding: '3px 6px',
      }}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder="Search..."
    />
  );
}

TableColumnSearch.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
  }).isRequired,
};

export default TableColumnSearch;
