/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loader from 'react-loader-spinner';

import jsonwebtoken from 'jsonwebtoken';

import { useQuery, useMutation } from '@apollo/client';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Formik, Field, Form, FieldArray } from 'formik';

import ProjectFormGroup from '../ProjectFormGroup';
import ProjectList from '../ProjectList';

import CLIENTS from '../../../queries/getClients';
import PROJECTS from '../../../queries/getProjects';
import USERS from '../../../queries/getUsers';
import CREATE_PROJECT from '../../../mutations/createProject';

import { isStaffOps, isAccountManager } from '../../../utils/auth';

import './index.css';

const ProjectForm = () => {
  // eslint-disable-next-line no-unused-vars
  const {
    data: dataProjects,
    loading: loadProjects,
    error: errorProjects,
  } = useQuery(PROJECTS);
  // eslint-disable-next-line no-unused-vars
  const { data: dataClients, loading: loadClients } = useQuery(CLIENTS);
  const { data: dataUsers, loading: loadUsers } = useQuery(USERS);

  const accessToken = localStorage.getItem('accessToken');
  const decodedToken = jsonwebtoken.decode(accessToken);

  // eslint-disable-next-line no-unused-vars
  const [newProject, { data: newDataProject, error }] = useMutation(
    CREATE_PROJECT,
    {
      update: (cache, result) => {
        const data = cache.readQuery({ query: PROJECTS });

        cache.writeQuery({
          query: PROJECTS,
          data: {
            getProjects: [result.data.createProject, ...data.getProjects],
          },
        });
      },
    }
  );

  const [show, setShow] = useState(false);
  const [startProjectDate, setStartProjectDate] = useState();
  const [endProjectDate, setEndProjectDate] = useState();
  const [startResDate, setStartResDate] = useState([]);
  const [endResDate, setEndResDate] = useState([]);
  const [billable, setBillable] = useState(false);
  const [utilizationEligible, setUtilizationEligible] = useState(false);
  const [notBillable, setNotBillable] = useState(false);
  const [overtimePaid, setOvertimePaid] = useState(false);
  const [overtimeNoPay, setOvertimeNoPay] = useState(false);
  const [onCall, setOnCall] = useState(false);
  const [enableExpenses, setEnableExpenses] = useState(false);
  const [expPolicy, setExpPolicy] = useState(false);
  const [psaMsa, setPsaMsa] = useState(false);
  const [nda, setNda] = useState(false);
  const [sowCoCr, setSowCoCr] = useState(false);

  useEffect(() => {
    if (show === false) {
      setStartProjectDate(null);
      setEndProjectDate(null);
      setStartResDate([]);
      setEndResDate([]);
      setBillable(false);
      setUtilizationEligible(false);
      setNotBillable(false);
      setOvertimePaid(false);
      setOvertimeNoPay(false);
      setOnCall(false);
      setEnableExpenses(false);
      setExpPolicy(false);
      setPsaMsa(false);
      setNda(false);
      setSowCoCr(false);
    }
  }, [show]);

  const handleViewProjectModal = () => {
    setShow((prevState) => !prevState);
  };

  if (loadProjects) {
    return (
      <Loader
        className="loader"
        type="Oval"
        color="black"
        height={75}
        width={75}
      />
    );
  }

  if (errorProjects) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      {dataProjects && dataProjects.getProjects ? (
        <ProjectList projects={dataProjects.getProjects} />
      ) : (
        <Loader
          className="loader"
          type="Oval"
          color="black"
          height={75}
          width={75}
        />
      )}

      {decodedToken.userRoleId !== 3 && (
        <Button variant="primary" onClick={handleViewProjectModal}>
          Project form
        </Button>
      )}

      <Modal
        show={show}
        onHide={handleViewProjectModal}
        dialogClassName="project-modal"
      >
        <Modal.Header closeButton className="dark">
          <Modal.Title>Create new project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark">
          <Formik
            initialValues={{
              client: '',
              invoicePaymentTerms: '',
              billingInstructions: '',
              billingContactName: '',
              billingContactEmail: '',
              billingContactPhone: '',
              engagementName: '',
              projectName: '',
              engagementManagerId: '',
              projectManagerId: '',
              engagementType: '',
              billable: '',
              utilizationEligible: '',
              goToMarket: '',
              businessUnit: '',
              serviceArea: '',
              projectCostcenter: '',
              salesLeadId: '',
              startDate: '',
              endDate: '',
              budget: '',
              travelAndExpenseApproverId: '',
              enableAdditionalTimeTypesNotBillable: '',
              enableAdditionalTimeTypesOverTimePaid: '',
              enableAdditionalTimeTypesOverTimeNoPay: '',
              enableAdditionalTimeTypesOnCall: '',
              enableExpenses: '',
              expensePolicy: '',
              psaMsaExecuted: '',
              ndaExecuted: '',
              sowCoCrExecuted: '',
              resourceAssignment: [
                {
                  roleName: '',
                  resourceName: '',
                  startDate: '',
                  endDate: '',
                  billRate: '',
                },
              ],
            }}
            onSubmit={(values, { setSubmitting }) => {
              newProject({
                variables: {
                  createProjectInput: {
                    clientId: values.client[0].id,
                    invoicePaymentTerms: values.invoicePaymentTerms,
                    billingInstructions: values.billingInstructions,
                    billingContact: {
                      name: values.billingContactName,
                      email: values.billingContactEmail,
                      phone: values.billingContactPhone
                        ? values.billingContactPhone
                        : null,
                    },
                    engagementName: values.engagementName,
                    name: values.projectName,
                    engagementManagerId: values.engagementManagerId[0].id,
                    projectManagerId: values.projectManagerId[0].id,
                    engagementType:
                      typeof values.engagementType === 'object'
                        ? values.engagementType[0] ||
                          values.engagementType[0].engagementType
                        : values.engagementType,
                    billable,
                    utilizationEligible,
                    goToMarket:
                      typeof values.gotoMarket === 'object'
                        ? values.gotoMarket[0]
                        : values.gotoMarket,
                    businessUnit:
                      typeof values.businessUnit === 'object'
                        ? values.businessUnit[0]
                        : values.businessUnit,
                    serviceArea:
                      typeof values.serviceArea === 'object'
                        ? values.serviceArea[0]
                        : values.serviceArea,
                    projectCostcenter:
                      typeof values.projectCostcenter === 'object'
                        ? values.projectCostcenter[0]
                        : values.projectCostcenter,
                    salesLeadId: values.salesLeadId[0].id,
                    startDate: startProjectDate.toISOString(),
                    endDate: endProjectDate.toISOString(),
                    budget: values.budget,
                    travelAndExpenseApproverId:
                      values.travelAndExpenseApproverId[0].id,
                    enableAdditionalTimeTypesNotBillable: notBillable,
                    enableAdditionalTimeTypesOverTimePaid: overtimePaid,
                    enableAdditionalTimeTypesOverTimeNoPay: overtimeNoPay,
                    enableAdditionalTimeTypesOnCall: onCall,
                    enableExpenses,
                    expensePolicy: expPolicy,
                    psaMsaExecuted: psaMsa,
                    ndaExecuted: nda,
                    sowCoCrExecuted: sowCoCr,
                    resources: values.resourceAssignment.map(
                      (element, index) => ({
                        userId: element.resourceName[0].id,
                        role:
                          typeof element.roleName === 'object'
                            ? element.roleName[0]
                            : element.roleName,
                        startDate: startResDate[index].toISOString(),
                        endDate: endResDate[index].toISOString(),
                        billRate: element.billRate,
                      })
                    ),
                  },
                },
              });

              setSubmitting(false);

              handleViewProjectModal();
            }}
          >
            {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
              <Form>
                <Field name="client">
                  {({ field }) => (
                    <>
                      <FormGroup className="mb-3" controlId="client">
                        <FormLabel>Clients:</FormLabel>
                        <Typeahead
                          inputProps={{ required: true }}
                          id="client-select"
                          options={dataClients.getClients.map((element) => ({
                            id: element.id,
                            label: element.name,
                          }))}
                          value={field.value}
                          placeholder="Select client"
                          onChange={(selected) => {
                            const value = selected.length > 0 ? selected : '';
                            setFieldValue('client', value);
                          }}
                          // eslint-disable-next-line no-unused-vars
                          onInputChange={(text, e) => {
                            setFieldValue('client', text);
                          }}
                          // eslint-disable-next-line no-unused-vars
                          onBlur={(e) => {
                            setFieldTouched('client', true);
                          }}
                        />
                      </FormGroup>
                      {field.value && field.value[0]?.id ? (
                        <>
                          <ProjectFormGroup
                            client={
                              field.value
                                ? dataClients.getClients.find(
                                    (element) =>
                                      element.id === field.value[0].id
                                  )
                                : {}
                            }
                          />
                          <Row>
                            <Col>
                              <Field name="invoicePaymentTerms">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="invoicePaymentTerms"
                                  >
                                    <FormLabel>
                                      Invoice payment terms:
                                    </FormLabel>
                                    <FormControl
                                      required
                                      type="text"
                                      placeholder="Enter invoice payment terms"
                                      value={ff.value}
                                      onChange={ff.onChange}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingInstructions">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingInstructions"
                                  >
                                    <FormLabel>Billing instructions:</FormLabel>
                                    <FormControl
                                      required
                                      type="text"
                                      placeholder="Enter billing instructions"
                                      value={ff.value}
                                      onChange={ff.onChange}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                          </Row>

                          <FormLabel>
                            <b>BILLING CONTACT</b>
                          </FormLabel>

                          <Row>
                            <Col>
                              <Field name="billingContactName">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactName"
                                  >
                                    <FormLabel>Billing contact name:</FormLabel>
                                    <FormControl
                                      required
                                      type="text"
                                      placeholder="Enter billing contact name"
                                      value={ff.value}
                                      onChange={ff.onChange}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingContactEmail">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactEmail"
                                  >
                                    <FormLabel>
                                      Billing contact email:
                                    </FormLabel>
                                    <FormControl
                                      required
                                      type="email"
                                      placeholder="Enter billing contact email"
                                      value={ff.value}
                                      onChange={ff.onChange}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingContactPhone">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactPhone"
                                  >
                                    <FormLabel>
                                      Billing contact phone:
                                    </FormLabel>
                                    <FormControl
                                      required
                                      type="phone"
                                      placeholder="Enter billing contact phone"
                                      value={ff.value}
                                      onChange={ff.onChange}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </>
                  )}
                </Field>
                <FormLabel>
                  <b>ENGAGEMENT INFORMATION</b>
                </FormLabel>
                <Row>
                  <Col>
                    <Field name="engagementName">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="engagementName">
                          <FormLabel>Engagement name:</FormLabel>
                          <FormControl
                            required
                            type="text"
                            placeholder="Enter engagement name"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectName">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="projectName">
                          <FormLabel>Project name:</FormLabel>
                          <FormControl
                            required
                            type="text"
                            placeholder="Enter project name"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field name="engagementManagerId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="engagementManagerId"
                        >
                          <FormLabel>Engagement manager:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="engagement-manager-select"
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select engagement manager"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('engagementManagerId', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('engagementManagerId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('engagementManagerId', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectManagerId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="projectManagerId"
                        >
                          <FormLabel>Project manager:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="project-manager-select"
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select project manager"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('projectManagerId', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('projectManagerId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('projectManagerId', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Field name="engagementType">
                  {() => (
                    <FormGroup className="mb-3" controlId="engagementType">
                      <FormLabel>Engagement type:</FormLabel>
                      <Typeahead
                        allowNew
                        inputProps={{ required: true }}
                        id="engagement-type-select"
                        labelKey="engagementType"
                        options={[
                          'T&M',
                          'Fixed Bid',
                          'Retainer Service',
                          'Not to Exceed',
                        ]}
                        placeholder="Select or type your engagement type"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('engagementType', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('engagementType', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('engagementType', true)}
                      />
                    </FormGroup>
                  )}
                </Field>

                <Row>
                  <Col sm={3} className="my-1">
                    <FormCheck
                      type="switch"
                      id="billable-switch"
                      checked={billable}
                      onChange={() => setBillable((prevState) => !prevState)}
                      label={<b>Billable</b>}
                    />
                  </Col>
                  <Col sm={2} className="my-1">
                    <FormCheck
                      type="switch"
                      id="utilization-eligible-switch"
                      checked={utilizationEligible}
                      onChange={() =>
                        setUtilizationEligible((prevState) => !prevState)
                      }
                      label={<b>Utilization eligible</b>}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field name="gotoMarket">
                      {() => (
                        <FormGroup className="mb-3" controlId="gotoMarket">
                          <FormLabel>GOTO Market:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="goto-market-select"
                            labelKey="gotoMarket"
                            options={['AWS', 'IBM', 'Other']}
                            placeholder="Select GOTO market"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('gotoMarket', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('gotoMarket', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) => setFieldTouched('gotoMarket', true)}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="businessUnit">
                      {() => (
                        <FormGroup className="mb-3" controlId="businessUnit">
                          <FormLabel>Business unit:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="business-unit-select"
                            labelKey="businessUnit"
                            options={[
                              'DA',
                              'ADD',
                              'MS',
                              'Responsum',
                              'SCOUT',
                              'Coshore',
                            ]}
                            placeholder="Select business unit"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('businessUnit', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('businessUnit', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('businessUnit', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="serviceArea">
                      {() => (
                        <FormGroup className="mb-3" controlId="serviceArea">
                          <FormLabel>Service area:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="service-area-select"
                            labelKey="serviceArea"
                            options={[
                              'AAST',
                              'ADST',
                              'AMS',
                              'BDST',
                              'EV',
                              'RVST',
                            ]}
                            placeholder="Select service area"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('serviceArea', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('serviceArea', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) => setFieldTouched('serviceArea', true)}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectCostcenter">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="projectCostcenter"
                        >
                          <FormLabel>Project costcenter:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="project-costcenter-select"
                            labelKey="projectCostcenter"
                            options={['US', 'CR']}
                            placeholder="Select project costcenter"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('projectCostcenter', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('projectCostcenter', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('projectCostcenter', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Field name="salesLeadId">
                  {() => (
                    <FormGroup className="mb-3" controlId="salesLeadId">
                      <FormLabel>Sales lead:</FormLabel>
                      <Typeahead
                        inputProps={{ required: true }}
                        id="sales-lead-select"
                        options={dataUsers.getUsers.map((element) => ({
                          id: element.id,
                          label: element.username,
                        }))}
                        placeholder="Select sales lead"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('salesLeadId', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('salesLeadId', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('salesLeadId', true)}
                      />
                    </FormGroup>
                  )}
                </Field>

                <FormLabel>
                  <b>PROJECT INFORMATION</b>
                </FormLabel>

                <Row>
                  <Col>
                    <Field name="startDate">
                      {() => (
                        <FormGroup className="mb-3" controlId="startDate">
                          <FormLabel>Select project start date:</FormLabel>
                          <DatePicker
                            required
                            selected={startProjectDate}
                            onChange={(date) => setStartProjectDate(date)}
                            selectsStart
                            startDate={startProjectDate}
                            endDate={endProjectDate}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="endDate">
                      {() => (
                        <FormGroup className="mb-3" controlId="endDate">
                          <FormLabel>Select project end date:</FormLabel>
                          <DatePicker
                            required
                            selected={endProjectDate}
                            onChange={(date) => setEndProjectDate(date)}
                            selectsEnd
                            startDate={startProjectDate}
                            endDate={endProjectDate}
                            minDate={startProjectDate}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field name="budget">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="budget">
                          <FormLabel>Project budget:</FormLabel>
                          <FormControl
                            required
                            type="number"
                            step="0.01"
                            placeholder="Enter project budget"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="travelAndExpenseApproverId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="travelAndExpenseApproverId"
                        >
                          <FormLabel>Travel and expense approver:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            id="travel-and-expense-approver-select"
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select travel and expense approver"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue(
                                'travelAndExpenseApproverId',
                                value
                              );
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('travelAndExpenseApproverId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched(
                                'travelAndExpenseApproverId',
                                true
                              )
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormLabel>Additional time types:</FormLabel>
                    <FormCheck
                      type="switch"
                      id="not-billable-switch"
                      checked={notBillable}
                      onChange={() => setNotBillable((prevState) => !prevState)}
                      label={<b>Not billable</b>}
                    />

                    <FormCheck
                      type="switch"
                      id="overtime-paid-switch"
                      checked={overtimePaid}
                      onChange={() =>
                        setOvertimePaid((prevState) => !prevState)
                      }
                      label={<b>Overtime paid</b>}
                    />

                    <FormCheck
                      type="switch"
                      id="overtime-no-pay-switch"
                      checked={overtimeNoPay}
                      onChange={() =>
                        setOvertimeNoPay((prevState) => !prevState)
                      }
                      label={<b>Overtime no pay</b>}
                    />

                    <FormCheck
                      type="switch"
                      id="on-call-switch"
                      checked={onCall}
                      onChange={() => setOnCall((prevState) => !prevState)}
                      label={<b>OnCall</b>}
                    />
                  </Col>
                  <Col>
                    <FormLabel>Expenses:</FormLabel>
                    <FormCheck
                      type="switch"
                      id="enable-expenses-switch"
                      checked={enableExpenses}
                      onChange={() =>
                        setEnableExpenses((prevState) => !prevState)
                      }
                      label={<b>Enable expenses</b>}
                    />
                    <FormCheck
                      type="switch"
                      id="expense-policy-switch"
                      checked={expPolicy}
                      onChange={() => setExpPolicy((prevState) => !prevState)}
                      label={
                        <>
                          <b>{expPolicy ? 'Client' : 'iOLAP'}</b> (Enable for
                          client policy, else iOLAP policy)
                        </>
                      }
                    />
                  </Col>
                  <Col>
                    <FormLabel>Other:</FormLabel>
                    <FormCheck
                      type="switch"
                      id="psa-msa-executed-switch"
                      checked={psaMsa}
                      onChange={() => setPsaMsa((prevState) => !prevState)}
                      label={<b>PSA/MSA Executed</b>}
                    />

                    <FormCheck
                      type="switch"
                      id="nda-executed-switch"
                      checked={nda}
                      onChange={() => setNda((prevState) => !prevState)}
                      label={<b>NDA Executed</b>}
                    />

                    <FormCheck
                      type="switch"
                      checked={sowCoCr}
                      onChange={() => setSowCoCr((prevState) => !prevState)}
                      id="sow-co-cr-executed-switch"
                      label={<b>SOW/CO/CR Executed</b>}
                    />
                  </Col>
                </Row>

                <FormLabel className="mt-2">
                  <b>RESOURCE ASSIGMENT</b>
                </FormLabel>

                <FieldArray name="resourceAssignment">
                  {/* eslint-disable-next-line no-unused-vars */}
                  {({ insert, remove, push }) => (
                    <>
                      {values.resourceAssignment.length > 0 &&
                        values.resourceAssignment.map((element, index) => (
                          <React.Fragment key={index}>
                            <Row>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].roleName`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].roleName`}
                                    >
                                      <FormLabel>Role name:</FormLabel>
                                      <Typeahead
                                        allowNew
                                        inputProps={{ required: true }}
                                        id="role-name-select"
                                        labelKey={`resourceAssignment[${index}].roleName`}
                                        options={[
                                          'Frontend developer',
                                          'Backend developer',
                                          'Fullstack developer',
                                          'QA Engineer',
                                          'DevOps Engineer',
                                          'Data Engineer',
                                          'Analytics Engineer',
                                          'ETL Developer',
                                          'Reporting Engineer',
                                        ]}
                                        placeholder="Select role name"
                                        onChange={(selected) => {
                                          const value =
                                            selected.length > 0 ? selected : '';
                                          setFieldValue(
                                            `resourceAssignment[${index}].roleName`,
                                            value
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onInputChange={(text, e) => {
                                          setFieldValue(
                                            `resourceAssignment[${index}].roleName`,
                                            text
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onBlur={(e) =>
                                          setFieldTouched(
                                            `resourceAssignment[${index}].roleName`,
                                            true
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].resourceName`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].resourceName`}
                                    >
                                      <FormLabel>Resource name:</FormLabel>
                                      <Typeahead
                                        inputProps={{ required: true }}
                                        id="resource-name-select"
                                        options={dataUsers.getUsers.map(
                                          (user) => ({
                                            id: user.id,
                                            label: user.username,
                                          })
                                        )}
                                        placeholder="Select resource name"
                                        onChange={(selected) => {
                                          const value =
                                            selected.length > 0 ? selected : '';
                                          setFieldValue(
                                            `resourceAssignment[${index}].resourceName`,
                                            value
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onInputChange={(text, e) => {
                                          setFieldValue(
                                            `resourceAssignment[${index}].resourceName`,
                                            text
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onBlur={(e) =>
                                          setFieldTouched(
                                            `resourceAssignment[${index}].resourceName`,
                                            true
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].startDate`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].startDate`}
                                    >
                                      <FormLabel>
                                        Select resource start date:
                                      </FormLabel>
                                      <DatePicker
                                        required
                                        selected={startResDate[index]}
                                        onChange={(date) =>
                                          setStartResDate([
                                            ...startResDate,
                                            date,
                                          ])
                                        }
                                        selectsStart
                                        startDate={startResDate[index]}
                                        endDate={endResDate[index]}
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].endDate`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].startDate`}
                                    >
                                      <FormLabel>
                                        Select resource end date:
                                      </FormLabel>
                                      <DatePicker
                                        required
                                        selected={endResDate[index]}
                                        onChange={(date) =>
                                          setEndResDate([...endResDate, date])
                                        }
                                        selectsEnd
                                        startDate={startResDate[index]}
                                        endDate={endResDate[index]}
                                        minDate={startResDate[index]}
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Field
                              name={`resourceAssignment[${index}].billRate`}
                            >
                              {({ field }) => (
                                <FormGroup
                                  className="mb-3"
                                  controlId={`resourceAssignment[${index}].billRate`}
                                >
                                  <FormLabel>Project bill rate:</FormLabel>
                                  <FormControl
                                    required
                                    type="number"
                                    step="0.01"
                                    placeholder="Enter project bill rate (in $ (dollars))"
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                </FormGroup>
                              )}
                            </Field>

                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setStartResDate(
                                  startResDate.filter(
                                    (el, idx) => idx !== index
                                  )
                                );

                                setEndResDate(
                                  endResDate.filter((el, idx) => idx !== index)
                                );
                                remove(index);
                              }}
                            >
                              Delete resource
                            </Button>
                          </React.Fragment>
                        ))}

                      <Row>
                        <Col>
                          <Button
                            className="my-1"
                            type="button"
                            variant="success"
                            onClick={() =>
                              push({
                                roleName: '',
                                resourceName: '',
                                startDate: '',
                                endDate: '',
                                billRate: '',
                              })
                            }
                          >
                            Add new resource assignment
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Submit'}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectForm;
