import jsonwebtoken from 'jsonwebtoken';

const ACCESS_TOKEN = 'accessToken';

export const isLoggedIn = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken !== 'undefined' && accessToken !== null) {
    try {
      const decodedToken = jsonwebtoken.decode(accessToken);
      return !!decodedToken.userId;
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      return false;
    }
  }
  return false;
};

export const isStaffOps = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken !== 'undefined' && accessToken !== null) {
    try {
      const decodedToken = jsonwebtoken.decode(accessToken);
      return decodedToken.userRoleName === 'StaffOps';
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      return false;
    }
  }
  return false;
};

export const isAccountManager = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken !== 'undefined' && accessToken !== null) {
    try {
      const decodedToken = jsonwebtoken.decode(accessToken);
      return decodedToken.userRoleName === 'Account Manager';
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      return false;
    }
  }
  return false;
};

export const isAccounting = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken !== 'undefined' && accessToken !== null) {
    try {
      const decodedToken = jsonwebtoken.decode(accessToken);
      return decodedToken.userRoleName === 'Accounting';
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      return false;
    }
  }
  return false;
};
