import React from 'react';
import PropTypes from 'prop-types';

import ProjectForm from './ProjectForm';

const Projects = ({ accessLevel }) => <ProjectForm accessLevel={accessLevel} />;

Projects.propTypes = {
  accessLevel: PropTypes.number,
};

Projects.defaultProps = {
  accessLevel: 0,
};

export default Projects;
