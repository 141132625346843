// eslint-disable-next-line no-unused-vars
import React from 'react';

import { BrowserRouter } from 'react-router-dom';
// import { useJwt } from 'react-jwt';

import Routes from '../Routes';

import './index.css';

// const accessToken = localStorage.getItem('accessToken');

const App = () => (
  // const { decodedToken, isExpired } = useJwt(accessToken);

  // const [accessLevel, setAccessLevel] = useState();
  // const [loggedIn, setLoggedIn] = useState(false);

  // console.log(isExpired);

  // useEffect(() => {
  //   (async () => {
  //     if (decodedToken !== null) {
  //       setAccessLevel(decodedToken.userRoleId);
  //       setLoggedIn((prevState) => !prevState);
  //     } else {
  //       setAccessLevel(0);
  //     }
  //   })();
  // }, [decodedToken]);

  // console.log(decodedToken);

  <BrowserRouter>
    <div className="App">
      <Routes />
    </div>
  </BrowserRouter>
);
export default App;
