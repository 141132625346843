import { useMemo } from 'react';

import { matchSorter } from 'match-sorter';

function fuzzyTextFilter(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilter.autoRemove = (val) => !val;

const useFilter = () => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilter,

      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  return filterTypes;
};

export default useFilter;
