import { gql } from '@apollo/client';

const NEW_USER = gql`
  mutation CreateUserMutation($createUserInput2: CreateUserInput!) {
    createUser(input: $createUserInput2) {
      id
      username
      password
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

export default NEW_USER;
