import { gql } from '@apollo/client';

const NEW_CLIENT = gql`
  mutation createClient($createClientInput: CreateClientInput!) {
    createClient(input: $createClientInput) {
      id
      name
      country
      poNumber
      city
      street
      streetNumber
      createdAt
      updatedAt
    }
  }
`;

export default NEW_CLIENT;
