/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useJwt } from 'react-jwt';

import jsonwebtoken from 'jsonwebtoken';

import REFRESH_TOKENS from '../mutations/refreshTokens';

const httpLink = createHttpLink({
  uri: 'https://api.psa.iolap.academy/',
});

const getNewToken = async () => {
  // eslint-disable-next-line no-use-before-define
  const response = await client.mutate({
    mutation: REFRESH_TOKENS,
    variables: {
      getNewTokensInput: {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    },
  });

  const newAccessToken = response.data.getNewTokens.accessToken;
  const newRefreshToken = response.data.getNewTokens.refreshToken;

  return { newAccessToken, newRefreshToken };
};

const authLink = setContext(async (request, { headers }) => {
  let accessToken = localStorage.getItem('accessToken');

  if (!accessToken || request.operationName === 'GetNewTokensMutation') {
    return { headers };
  }

  try {
    const decodedToken = jsonwebtoken.decode(accessToken);

    if (Date.now() >= decodedToken.exp * 1000) {
      const { newAccessToken, newRefreshToken } = await getNewToken();

      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);

      accessToken = newAccessToken;
    }

    return {
      headers: {
        ...headers,
        ...(accessToken && { authorization: `Bearer ${accessToken}` }),
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return { headers };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
