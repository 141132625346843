/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Modal from 'react-bootstrap/Modal';

import Loader from 'react-loader-spinner';

import { Typeahead } from 'react-bootstrap-typeahead';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Field, Form, FieldArray } from 'formik';

import { isStaffOps } from '../../../utils/auth';

import ProjectFormGroup from '../ProjectFormGroup';

import UPDATE_PROJECT from '../../../mutations/updateProject';
import USERS from '../../../queries/getUsers';
import CLIENTS from '../../../queries/getClients';

const ViewProject = ({ show, onHide, project }) => {
  const [updateProject, { data: updateProjectData }] =
    useMutation(UPDATE_PROJECT);
  const { data: dataClients, loading: loadClients } = useQuery(CLIENTS);
  const { data: dataUsers, loading: loadUsers } = useQuery(USERS);

  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [startResDate, setStartResDate] = useState([]);
  const [endResDate, setEndResDate] = useState([]);

  const [billable, setBillable] = useState();
  const [utilizationEligible, setUtilizationEligible] = useState();
  const [notBillable, setNotBillable] = useState();
  const [overtimePaid, setOvertimePaid] = useState();
  const [overtimeNoPay, setOvertimeNoPay] = useState();
  const [onCall, setOnCall] = useState();
  const [enableExpenses, setEnableExpenses] = useState();
  const [expensePolicy, setExpensePolicy] = useState();
  const [psaMsa, setPsaMsa] = useState();
  const [nda, setNda] = useState();
  const [sowCoCr, setSowCoCr] = useState();

  const handleEditCheck = () => {
    setChecked((prevState) => !prevState);

    if (checked === true) {
      setDisabled(false);
    } else if (checked === false) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (show === false) {
      setDisabled(false);
      setChecked(false);
    }
  }, [show]);

  if (loadUsers && loadClients) {
    <Loader
      className="loader"
      type="Oval"
      color="black"
      height={75}
      width={75}
    />;
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName="project-modal">
      <Modal.Header closeButton className="dark">
        <Modal.Title>Project information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark">
        {project && (
          <Formik
            initialValues={{
              client: '',
              invoicePaymentTerms: '',
              billingInstructions: '',
              billingContactName: '',
              billingContactEmail: '',
              billingContactPhone: '',
              engagementName: '',
              projectName: project.name || '',
              engagementManagerId: '',
              projectManagerId: '',
              engagementType: '',
              billable: '',
              utilizationEligible: '',
              goToMarket: '',
              businessUnit: '',
              serviceArea: '',
              projectCostcenter: '',
              salesLeadId: '',
              startDate: project.startDate
                ? moment(project.startDate, 'YYYY-MM-DD').toDate()
                : moment(),
              endDate: project.endDate
                ? moment(project.endDate, 'YYYY-MM-DD').toDate()
                : moment(),
              budget: '',
              travelAndExpenseApproverId: '',
              enableAdditionalTimeTypesNotBillable: '',
              enableAdditionalTimeTypesOverTimePaid: '',
              enableAdditionalTimeTypesOverTimeNoPay: '',
              enableAdditionalTimeTypesOnCall: '',
              enableExpenses: '',
              expensePolicy: '',
              psaMsaExecuted: '',
              ndaExecuted: '',
              sowCoCrExecuted: '',
              resourceAssignment: project.resources,
              startResDate: project.resources
                ? project.resources.map(
                    (element) => new Date(element.startDate)
                  )
                : [],
              endResDate: project.resources
                ? project.resources.map((element) => new Date(element.endDate))
                : [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              const updatedProject = {
                variables: {
                  updateProjectInput: {
                    id: project.id,
                    clientId:
                      values.client[0] != null
                        ? values.client[0].id
                        : project.client.id,
                    invoicePaymentTerms: values.invoicePaymentTerms,
                    billingInstructions: values.billingInstructions,
                    billingContact: {
                      id: project.billingContact.id,
                      name: values.billingContactName,
                      email: values.billingContactEmail,
                      phone: values.billingContactPhone,
                    },
                    engagementName: values.engagementName,
                    name: values.projectName,
                    engagementManagerId:
                      values.engagementManagerId[0] != null
                        ? values.engagementManagerId[0].id
                        : project.engagementManager.id,
                    projectManagerId:
                      values.engagementManagerId[0] != null
                        ? values.engagementManagerId[0].id
                        : project.engagementManager.id,
                    engagementType: values.engagementType[0],
                    billable:
                      typeof billable !== 'undefined'
                        ? !!billable
                        : project.billable,
                    utilizationEligible:
                      typeof utilizationEligible !== 'undefined'
                        ? !!utilizationEligible
                        : project.utilizationEligible,
                    goToMarket: values.goToMarket[0],
                    businessUnit: values.businessUnit[0],
                    serviceArea: values.serviceArea[0],
                    projectCostcenter: values.projectCostcenter[0],
                    salesLeadId:
                      values.salesLeadId[0] != null
                        ? values.salesLeadId[0].id
                        : project.salesLead.id,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    budget: parseFloat(values.budget),
                    travelAndExpenseApproverId:
                      values.travelAndExpenseApproverId[0] != null
                        ? values.travelAndExpenseApproverId[0].id
                        : project.travelAndExpenseApprover.id,
                    enableAdditionalTimeTypesNotBillable:
                      typeof notBillable !== 'undefined'
                        ? !!notBillable
                        : project.enableAdditionalTimeTypesNotBillable,
                    enableAdditionalTimeTypesOnCall:
                      typeof onCall !== 'undefined'
                        ? !!onCall
                        : project.enableAdditionalTimeTypesOnCall,
                    enableAdditionalTimeTypesOverTimeNoPay:
                      typeof overtimeNoPay !== 'undefined'
                        ? !!overtimeNoPay
                        : project.enableAdditionalTimeTypesOverTimeNoPay,
                    enableAdditionalTimeTypesOverTimePaid:
                      typeof overtimePaid !== 'undefined'
                        ? !!overtimePaid
                        : project.enableAdditionalTimeTypesOverTimePaid,
                    enableExpenses:
                      typeof enableExpenses !== 'undefined'
                        ? !!enableExpenses
                        : project.enableExpenses,
                    expensePolicy:
                      typeof expensePolicy !== 'undefined'
                        ? !!expensePolicy
                        : project.expensePolicy,
                    psaMsaExecuted:
                      typeof psaMsa !== 'undefined'
                        ? !!psaMsa
                        : project.psaMsaExecuted,
                    ndaExecuted:
                      typeof nda !== 'undefined' ? !!nda : project.ndaExecuted,
                    sowCoCrExecuted:
                      typeof sowCoCr !== 'undefined'
                        ? !!sowCoCr
                        : project.sowCoCrExecuted,
                    resources:
                      values.resourceAssignment === []
                        ? project.resources
                        : values.resourceAssignment.map((element, index) => ({
                            id: project.resources[index]
                              ? project.resources[index].id
                              : null,
                            userId: element.user
                              ? element.user.id
                              : element.resourceName[0].id,
                            role:
                              element.roleName != null
                                ? element.roleName[0]
                                : project.resources[index].role,
                            billRate: element.billRate,
                            startDate: startResDate
                              ? startResDate[index]
                                ? startResDate[index]
                                : project.resources[index]
                                ? new Date(project.resources[index].startDate)
                                : new Date()
                              : new Date(),
                            endDate: endResDate
                              ? endResDate[index]
                                ? endResDate[index]
                                : project.resources[index]
                                ? new Date(project.resources[index].endDate)
                                : new Date()
                              : new Date(),
                          })),
                  },
                },
              };

              updateProject(updatedProject);

              setSubmitting(false);

              setDisabled(false);
              setChecked(false);

              onHide();
            }}
          >
            {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
              <Form>
                <Field name="client">
                  {({ field }) => (
                    <>
                      <FormGroup className="mb-3" controlId="client">
                        <FormLabel>Client:</FormLabel>
                        <Typeahead
                          disabled={!disabled}
                          inputProps={{ required: true }}
                          id="client-select"
                          options={dataClients.getClients.map((element) => ({
                            id: element.id,
                            label: element.name,
                          }))}
                          value={field.value}
                          defaultSelected={
                            project.client.name != null
                              ? [project.client.name]
                              : []
                          }
                          placeholder="Select client"
                          onChange={(selected) => {
                            const value = selected.length > 0 ? selected : '';
                            setFieldValue('client', value);
                          }}
                          // eslint-disable-next-line no-unused-vars
                          onInputChange={(text, e) => {
                            setFieldValue('client', text);
                          }}
                          // eslint-disable-next-line no-unused-vars
                          onBlur={(e) => {
                            setFieldTouched('client', true);
                          }}
                        />
                      </FormGroup>
                      {(field.value && field.value[0]?.id) || !disabled ? (
                        <>
                          <ProjectFormGroup
                            loadDefault
                            defaultProjectValues={project}
                            disable={!disabled}
                            client={
                              field.value
                                ? dataClients.getClients.find(
                                    (element) =>
                                      element.id === field.value[0].id
                                  )
                                : {}
                            }
                          />
                          <Row>
                            <Col>
                              <Field name="invoicePaymentTerms">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="invoicePaymentTerms"
                                  >
                                    <FormLabel>
                                      Invoice payment terms:
                                    </FormLabel>
                                    <FormControl
                                      disabled={!disabled}
                                      required
                                      type="text"
                                      placeholder="Enter invoice payment terms"
                                      onChange={ff.onChange}
                                      defaultValue={project.invoicePaymentTerms}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingInstructions">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingInstructions"
                                  >
                                    <FormLabel>Billing instructions:</FormLabel>
                                    <FormControl
                                      required
                                      type="text"
                                      placeholder="Enter billing instructions"
                                      onChange={ff.onChange}
                                      disabled={!disabled}
                                      defaultValue={project.billingInstructions}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                          </Row>

                          <FormLabel>
                            <b>BILLING CONTACT</b>
                          </FormLabel>

                          <Row>
                            <Col>
                              <Field name="billingContactName">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactName"
                                  >
                                    <FormLabel>Billing contact name:</FormLabel>
                                    <FormControl
                                      required
                                      disabled={!disabled}
                                      type="text"
                                      placeholder="Enter billing contact name"
                                      onChange={ff.onChange}
                                      defaultValue={project.billingContact.name}
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingContactEmail">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactEmail"
                                  >
                                    <FormLabel>
                                      Billing contact email:
                                    </FormLabel>
                                    <FormControl
                                      required
                                      disabled={!disabled}
                                      type="email"
                                      placeholder="Enter billing contact email"
                                      onChange={ff.onChange}
                                      defaultValue={
                                        project.billingContact.email
                                      }
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field name="billingContactPhone">
                                {({ field: ff }) => (
                                  <FormGroup
                                    className="mb-3"
                                    controlId="billingContactPhone"
                                  >
                                    <FormLabel>
                                      Billing contact phone:
                                    </FormLabel>
                                    <FormControl
                                      required
                                      disabled={!disabled}
                                      type="phone"
                                      placeholder="Enter billing contact phone"
                                      onChange={ff.onChange}
                                      defaultValue={
                                        project.billingContact.phone
                                      }
                                    />
                                  </FormGroup>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </>
                  )}
                </Field>
                <FormLabel>
                  <b>ENGAGEMENT INFORMATION</b>
                </FormLabel>
                <Row>
                  <Col>
                    <Field name="engagementName">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="engagementName">
                          <FormLabel>Engagement name:</FormLabel>
                          <FormControl
                            required
                            disabled={!disabled}
                            type="text"
                            placeholder="Enter engagement name"
                            onChange={field.onChange}
                            defaultValue={project.engagementName}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectName">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="projectName">
                          <FormLabel>Project name:</FormLabel>
                          <FormControl
                            disabled={!disabled}
                            required
                            type="text"
                            placeholder="Enter project name"
                            onChange={field.onChange}
                            defaultValue={field.value}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field name="engagementManagerId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="engagementManagerId"
                        >
                          <FormLabel>Engagement manager:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            disabled={!disabled}
                            id="engagement-manager-select"
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select engagement manager"
                            defaultSelected={
                              project.engagementManager.username != null
                                ? [project.engagementManager.username]
                                : []
                            }
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('engagementManagerId', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('engagementManagerId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('engagementManagerId', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectManagerId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="projectManagerId"
                        >
                          <FormLabel>Project manager:</FormLabel>
                          <Typeahead
                            inputProps={{ required: true }}
                            disabled={!disabled}
                            id="project-manager-select"
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select project manager"
                            defaultSelected={
                              project.projectManager.username != null
                                ? [project.projectManager.username]
                                : []
                            }
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('projectManagerId', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('projectManagerId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('projectManagerId', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Field name="engagementType">
                  {() => (
                    <FormGroup className="mb-3" controlId="engagementType">
                      <FormLabel>Engagement type:</FormLabel>
                      <Typeahead
                        allowNew
                        disabled={!disabled}
                        inputProps={{ required: true }}
                        id="engagement-type-select"
                        labelKey="engagementType"
                        options={[
                          'T&M',
                          'Fixed Bid',
                          'Retainer Service',
                          'Not to Exceed',
                        ]}
                        defaultSelected={
                          project.engagementType != null
                            ? [project.engagementType]
                            : []
                        }
                        placeholder="Select or type your engagement type"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('engagementType', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('engagementType', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('engagementType', true)}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Row>
                  <Col sm={3} className="my-1">
                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="billable-switch"
                      checked={
                        typeof billable !== 'undefined'
                          ? billable
                          : project.billable
                      }
                      onChange={() =>
                        setBillable((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.billable
                        )
                      }
                      label={<b>Billable</b>}
                    />
                  </Col>
                  <Col sm={2} className="my-1">
                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="utilization-eligible-switch"
                      checked={
                        typeof utilizationEligible !== 'undefined'
                          ? utilizationEligible
                          : project.utilizationEligible
                      }
                      onChange={() =>
                        setUtilizationEligible((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.utilizationEligible
                        )
                      }
                      label={<b>Utilization eligible</b>}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field name="gotoMarket">
                      {() => (
                        <FormGroup className="mb-3" controlId="gotoMarket">
                          <FormLabel>GOTO Market:</FormLabel>
                          <Typeahead
                            disabled={!disabled}
                            inputProps={{ required: true }}
                            id="goto-market-select"
                            defaultSelected={
                              project.goToMarket != null
                                ? [project.goToMarket]
                                : []
                            }
                            labelKey="gotoMarket"
                            options={['AWS', 'IBM', 'Other']}
                            placeholder="Select GOTO market"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('gotoMarket', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('gotoMarket', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) => setFieldTouched('gotoMarket', true)}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="businessUnit">
                      {() => (
                        <FormGroup className="mb-3" controlId="businessUnit">
                          <FormLabel>Business unit:</FormLabel>
                          <Typeahead
                            disabled={!disabled}
                            inputProps={{ required: true }}
                            id="business-unit-select"
                            labelKey="businessUnit"
                            defaultSelected={
                              project.businessUnit != null
                                ? [project.businessUnit]
                                : []
                            }
                            options={[
                              'DA',
                              'ADD',
                              'MS',
                              'Responsum',
                              'SCOUT',
                              'Coshore',
                            ]}
                            placeholder="Select business unit"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('businessUnit', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('businessUnit', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('businessUnit', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="serviceArea">
                      {() => (
                        <FormGroup className="mb-3" controlId="serviceArea">
                          <FormLabel>Service area:</FormLabel>
                          <Typeahead
                            disabled={!disabled}
                            inputProps={{ required: true }}
                            id="service-area-select"
                            labelKey="serviceArea"
                            defaultSelected={
                              project.serviceArea != null
                                ? [project.serviceArea]
                                : []
                            }
                            options={[
                              'AAST',
                              'ADST',
                              'AMS',
                              'BDST',
                              'EV',
                              'RVST',
                            ]}
                            placeholder="Select service area"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('serviceArea', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('serviceArea', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) => setFieldTouched('serviceArea', true)}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="projectCostcenter">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="projectCostcenter"
                        >
                          <FormLabel>Project costcenter:</FormLabel>
                          <Typeahead
                            disabled={!disabled}
                            inputProps={{ required: true }}
                            id="project-costcenter-select"
                            labelKey="projectCostcenter"
                            options={['US', 'CR']}
                            placeholder="Select project costcenter"
                            defaultSelected={
                              project.projectCostcenter != null
                                ? [project.projectCostcenter]
                                : []
                            }
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue('projectCostcenter', value);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('projectCostcenter', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched('projectCostcenter', true)
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Field name="salesLeadId">
                  {() => (
                    <FormGroup className="mb-3" controlId="salesLeadId">
                      <FormLabel>Sales lead:</FormLabel>
                      <Typeahead
                        disabled={!disabled}
                        inputProps={{ required: true }}
                        id="sales-lead-select"
                        options={dataUsers.getUsers.map((element) => ({
                          id: element.id,
                          label: element.username,
                        }))}
                        defaultSelected={
                          project.salesLead.username != null
                            ? [project.salesLead.username]
                            : []
                        }
                        placeholder="Select sales lead"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('salesLeadId', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('salesLeadId', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('salesLeadId', true)}
                      />
                    </FormGroup>
                  )}
                </Field>

                <FormLabel>
                  <b>PROJECT INFORMATION</b>
                </FormLabel>

                <Row>
                  <Col>
                    <Field name="startDate">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="startDate">
                          <FormLabel>Select project start date:</FormLabel>
                          <DatePicker
                            disabled={!disabled}
                            required
                            selected={field.value}
                            onChange={(date) =>
                              setFieldValue('startDate', date)
                            }
                            selectsStart
                            startDate={field.value}
                            endDate={values.endDate}
                            minDate={moment()}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="endDate">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="endDate">
                          <FormLabel>Select project end date:</FormLabel>
                          <DatePicker
                            disabled={!disabled}
                            required
                            selected={field.value}
                            onChange={(date) => {
                              setFieldValue('endDate', date);
                            }}
                            selectsEnd
                            startDate={values.startDate}
                            endDate={field.value}
                            minDate={values.startDate}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field name="budget">
                      {({ field }) => (
                        <FormGroup className="mb-3" controlId="budget">
                          <FormLabel>Project budget:</FormLabel>
                          <FormControl
                            disabled={!disabled}
                            defaultValue={project.budget}
                            required
                            type="number"
                            step="0.01"
                            placeholder="Enter project budget"
                            onChange={field.onChange}
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="travelAndExpenseApproverId">
                      {() => (
                        <FormGroup
                          className="mb-3"
                          controlId="travelAndExpenseApproverId"
                        >
                          <FormLabel>Travel and expense approver:</FormLabel>
                          <Typeahead
                            disabled={!disabled}
                            inputProps={{ required: true }}
                            id="travel-and-expense-approver-select"
                            defaultSelected={
                              project.travelAndExpenseApprover.username != null
                                ? [project.travelAndExpenseApprover.username]
                                : []
                            }
                            options={dataUsers.getUsers.map((element) => ({
                              id: element.id,
                              label: element.username,
                            }))}
                            placeholder="Select travel and expense approver"
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected : '';
                              setFieldValue(
                                'travelAndExpenseApproverId',
                                value
                              );
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onInputChange={(text, e) => {
                              setFieldValue('travelAndExpenseApproverId', text);
                            }}
                            // eslint-disable-next-line no-unused-vars
                            onBlur={(e) =>
                              setFieldTouched(
                                'travelAndExpenseApproverId',
                                true
                              )
                            }
                          />
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormLabel>Additional time types:</FormLabel>

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="not-billable-switch"
                      checked={
                        typeof notBillable !== 'undefined'
                          ? notBillable
                          : project.enableAdditionalTimeTypesNotBillable
                      }
                      onChange={() =>
                        setNotBillable((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.enableAdditionalTimeTypesNotBillable
                        )
                      }
                      label={<b>Not billable</b>}
                    />

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="overtime-paid-switch"
                      checked={
                        typeof overtimePaid !== 'undefined'
                          ? overtimePaid
                          : project.enableAdditionalTimeTypesOverTimePaid
                      }
                      onChange={() =>
                        setOvertimePaid((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.enableAdditionalTimeTypesOverTimePaid
                        )
                      }
                      label={<b>Overtime paid</b>}
                    />

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="overtime-no-pay-switch"
                      checked={
                        typeof overtimeNoPay !== 'undefined'
                          ? overtimeNoPay
                          : project.enableAdditionalTimeTypesOverTimeNoPay
                      }
                      onChange={() =>
                        setOvertimeNoPay((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.enableAdditionalTimeTypesOverTimeNoPay
                        )
                      }
                      label={<b>Overtime no pay</b>}
                    />

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="on-call-switch"
                      checked={
                        typeof onCall !== 'undefined'
                          ? onCall
                          : project.enableAdditionalTimeTypesOnCall
                      }
                      onChange={() =>
                        setOnCall((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.enableAdditionalTimeTypesOnCall
                        )
                      }
                      label={<b>OnCall</b>}
                    />
                  </Col>
                  <Col>
                    <FormLabel>Expenses:</FormLabel>
                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="enable-expenses-switch"
                      checked={
                        typeof enableExpenses !== 'undefined'
                          ? enableExpenses
                          : project.enableExpenses
                      }
                      onChange={() =>
                        setEnableExpenses((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.enableExpenses
                        )
                      }
                      label={<b>Enable expenses</b>}
                    />
                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="expense-policy-switch"
                      checked={
                        typeof expensePolicy !== 'undefined'
                          ? expensePolicy
                          : project.expensePolicy
                      }
                      onChange={() =>
                        setExpensePolicy((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.expensePolicy
                        )
                      }
                      label={
                        <>
                          <b>{expensePolicy ? 'Client' : 'iOLAP'}</b> (Enable
                          for client policy, else iOLAP policy)
                        </>
                      }
                    />
                  </Col>
                  <Col>
                    <FormLabel>Other:</FormLabel>
                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="psa-msa-executed-switch"
                      checked={
                        typeof psaMsa !== 'undefined'
                          ? psaMsa
                          : project.psaMsaExecuted
                      }
                      onChange={() =>
                        setPsaMsa((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.psaMsaExecuted
                        )
                      }
                      label={<b>PSA/MSA Executed</b>}
                    />

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      id="nda-executed-switch"
                      checked={
                        typeof nda !== 'undefined' ? nda : project.ndaExecuted
                      }
                      onChange={() =>
                        setNda((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.ndaExecuted
                        )
                      }
                      label={<b>NDA Executed</b>}
                    />

                    <FormCheck
                      disabled={!disabled}
                      type="switch"
                      checked={
                        typeof sowCoCr !== 'undefined'
                          ? sowCoCr
                          : project.sowCoCrExecuted
                      }
                      onChange={() =>
                        setSowCoCr((prevState) =>
                          typeof prevState !== 'undefined'
                            ? !prevState
                            : !project.sowCoCrExecuted
                        )
                      }
                      id="sow-co-cr-executed-switch"
                      label={<b>SOW/CO/CR Executed</b>}
                    />
                  </Col>
                </Row>

                <FormLabel className="mt-2">
                  <b>RESOURCE ASSIGMENT</b>
                </FormLabel>

                <FieldArray name="resourceAssignment">
                  {/* eslint-disable-next-line no-unused-vars */}
                  {({ insert, remove, push }) => (
                    <>
                      {values.resourceAssignment.length > 0 &&
                        values.resourceAssignment.map((element, index) => (
                          <React.Fragment key={index}>
                            <Row>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].roleName`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].roleName`}
                                    >
                                      <FormLabel>Role name:</FormLabel>
                                      <Typeahead
                                        disabled={!disabled}
                                        allowNew
                                        inputProps={{ required: true }}
                                        id="role-name-select"
                                        defaultSelected={
                                          project.resources[index] != null
                                            ? [project.resources[index].role]
                                            : []
                                        }
                                        labelKey={`resourceAssignment[${index}].roleName`}
                                        options={[
                                          'Frontend developer',
                                          'Backend developer',
                                          'Fullstack developer',
                                          'QA Engineer',
                                          'DevOps Engineer',
                                          'Data Engineer',
                                          'Analytics Engineer',
                                          'ETL Developer',
                                          'Reporting Engineer',
                                        ]}
                                        placeholder="Select role name"
                                        onChange={(selected) => {
                                          const value =
                                            selected.length > 0 ? selected : '';
                                          setFieldValue(
                                            `resourceAssignment[${index}].roleName`,
                                            value
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onInputChange={(text, e) => {
                                          setFieldValue(
                                            `resourceAssignment[${index}].roleName`,
                                            text
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onBlur={(e) =>
                                          setFieldTouched(
                                            `resourceAssignment[${index}].roleName`,
                                            true
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].resourceName`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].resourceName`}
                                    >
                                      <FormLabel>Resource name:</FormLabel>
                                      <Typeahead
                                        disabled={!disabled}
                                        inputProps={{ required: true }}
                                        id="resource-name-select"
                                        defaultSelected={
                                          project.resources[index] != null
                                            ? [
                                                project.resources[index].user
                                                  .username,
                                              ]
                                            : []
                                        }
                                        options={dataUsers.getUsers.map(
                                          (user) => ({
                                            id: user.id,
                                            label: user.username,
                                          })
                                        )}
                                        placeholder="Select resource name"
                                        onChange={(selected) => {
                                          const value =
                                            selected.length > 0 ? selected : '';
                                          setFieldValue(
                                            `resourceAssignment[${index}].resourceName`,
                                            value
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onInputChange={(text, e) => {
                                          setFieldValue(
                                            `resourceAssignment[${index}].resourceName`,
                                            text
                                          );
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        onBlur={(e) =>
                                          setFieldTouched(
                                            `resourceAssignment[${index}].resourceName`,
                                            true
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].startDate`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].startDate`}
                                    >
                                      <FormLabel>
                                        Select resource start date:
                                      </FormLabel>
                                      <DatePicker
                                        disabled={!disabled}
                                        required
                                        selected={
                                          startResDate
                                            ? startResDate[index]
                                              ? startResDate[index]
                                              : project.resources[index]
                                              ? new Date(
                                                  project.resources[
                                                    index
                                                  ].startDate
                                                )
                                              : new Date()
                                            : new Date()
                                        }
                                        onChange={(date) => {
                                          if (startResDate.length > 0) {
                                            if (startResDate[index]) {
                                              const old = [...startResDate];
                                              old[index] = date;
                                              setStartResDate(old);
                                            } else {
                                              setStartResDate([
                                                ...startResDate,
                                                date,
                                              ]);
                                            }
                                          } else {
                                            const old = project.resources.map(
                                              (resource) =>
                                                new Date(resource.startDate)
                                            );
                                            old[index] = date;
                                            setStartResDate(old);
                                          }
                                        }}
                                        selectsStart
                                        startDate={startResDate[index]}
                                        endDate={endResDate[index]}
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                              <Col>
                                <Field
                                  name={`resourceAssignment[${index}].endDate`}
                                >
                                  {() => (
                                    <FormGroup
                                      className="mb-3"
                                      controlId={`resourceAssignment[${index}].startDate`}
                                    >
                                      <FormLabel>
                                        Select resource end date:
                                      </FormLabel>
                                      <DatePicker
                                        disabled={!disabled}
                                        required
                                        selected={
                                          endResDate
                                            ? endResDate[index]
                                              ? endResDate[index]
                                              : project.resources[index]
                                              ? new Date(
                                                  project.resources[
                                                    index
                                                  ].endDate
                                                )
                                              : new Date()
                                            : new Date()
                                        }
                                        onChange={(date) => {
                                          if (endResDate.length > 0) {
                                            if (endResDate[index]) {
                                              const old = [...endResDate];
                                              old[index] = date;
                                              setEndResDate(old);
                                            } else {
                                              setEndResDate([
                                                ...endResDate,
                                                date,
                                              ]);
                                            }
                                          } else {
                                            const old = project.resources.map(
                                              (resource) =>
                                                new Date(resource.endDate)
                                            );
                                            old[index] = date;
                                            setEndResDate(old);
                                          }
                                        }}
                                        selectsEnd
                                        startDate={startResDate[index]}
                                        endDate={endResDate[index]}
                                        minDate={startResDate[index]}
                                      />
                                    </FormGroup>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Field
                              name={`resourceAssignment[${index}].billRate`}
                            >
                              {({ field }) => (
                                <FormGroup
                                  className="mb-3"
                                  controlId={`resourceAssignment[${index}].billRate`}
                                >
                                  <FormLabel>Project bill rate:</FormLabel>
                                  <FormControl
                                    disabled={!disabled}
                                    required
                                    defaultValue={
                                      project.resources[index]
                                        ? project.resources[index].billRate
                                        : values.resourceAssignment[index]
                                            .billRate
                                    }
                                    type="number"
                                    step="0.01"
                                    placeholder="Enter project bill rate (in $ (dollars))"
                                    onChange={field.onChange}
                                  />
                                </FormGroup>
                              )}
                            </Field>

                            <Button
                              disabled={!disabled}
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setStartResDate(
                                  startResDate.filter(
                                    (el, idx) => idx !== index
                                  )
                                );

                                setEndResDate(
                                  endResDate.filter((el, idx) => idx !== index)
                                );
                                remove(index);
                              }}
                            >
                              Delete resource
                            </Button>
                          </React.Fragment>
                        ))}

                      <Row>
                        <Col>
                          {isStaffOps && (
                            <Button
                              disabled={!disabled}
                              className="my-1"
                              type="button"
                              variant="success"
                              onClick={() =>
                                push({
                                  roleName: '',
                                  resourceName: '',
                                  startDate: '',
                                  endDate: '',
                                  billRate: '',
                                })
                              }
                            >
                              Add new resource
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>

                {isStaffOps && (
                  <>
                    <FormCheck
                      type="switch"
                      id="custom-switch"
                      label={<b>EDIT PROJECT</b>}
                      checked={checked}
                      onChange={handleEditCheck}
                    />

                    <Button
                      type="submit"
                      variant="primary"
                      disabled={!disabled || isSubmitting}
                    >
                      Submit changes
                    </Button>
                  </>
                )}
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

ViewProject.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.shape(PropTypes.object),
};

ViewProject.defaultProps = {
  show: false,
  project: undefined,
};

export default ViewProject;
