import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Modal from 'react-bootstrap/Modal';

import { Typeahead } from 'react-bootstrap-typeahead';
import { useMutation } from '@apollo/client';
import { Formik, Field, Form } from 'formik';

import UPDATE_CLIENT from '../../../mutations/updateClient';
import countries from '../../../constants/countries';

const ViewClient = ({ show, onHide, clients }) => {
  // eslint-disable-next-line no-unused-vars
  const [updateClient, { data: updateClientData }] = useMutation(UPDATE_CLIENT);

  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleEditCheck = () => {
    setChecked((prevState) => !prevState);

    if (checked === true) {
      setDisabled(false);
    } else if (checked === false) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (show === false) {
      setDisabled(false);
      setChecked(false);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="dark">
        <Modal.Title>Client information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark">
        <Formik
          initialValues={{
            name: '',
            address: '',
            city: '',
            street: '',
            streetNum: '',
            poNum: '',
            country: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateClient({
              variables: {
                updateClientInput: {
                  id: clients.id,
                  name: values.name,
                  city: values.city,
                  street: values.street,
                  streetNumber: parseInt(values.streetNum, 10),
                  poNumber: parseInt(values.poNum, 10),
                  country: values.country[0],
                },
              },
            });

            setSubmitting(false);

            setDisabled(false);
            setChecked(false);

            onHide();
          }}
        >
          {({ isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form>
              <Field name="name">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="name">
                    <FormLabel>Name:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      required
                      type="text"
                      placeholder="Enter name"
                      defaultValue={clients.name}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="city">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="city">
                    <FormLabel>City:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      type="text"
                      placeholder="City not specified"
                      defaultValue={clients.city}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="street">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="street">
                    <FormLabel>Street:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      type="text"
                      placeholder="Street not specified"
                      defaultValue={clients.street}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="streetNum">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="streetNum">
                    <FormLabel>Street number:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      type="number"
                      placeholder="Street number not specified"
                      defaultValue={clients.streetNumber}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="poNum">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="poNum">
                    <FormLabel>PO Number:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      type="number"
                      placeholder="PO number not specified"
                      defaultValue={clients.poNumber}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="country">
                {() => (
                  <FormGroup className="mb-3" controlId="country">
                    <FormLabel>Country:</FormLabel>
                    <Typeahead
                      id="country-select"
                      labelKey="name"
                      options={countries.map((element) => `${element}`)}
                      placeholder="Country not specified"
                      defaultSelected={
                        clients.country != null ? [clients.country] : []
                      }
                      disabled={!disabled}
                      onChange={(selected) => {
                        const value = selected.length > 0 ? selected : '';
                        setFieldValue('country', value);
                      }}
                      // eslint-disable-next-line no-unused-vars
                      onInputChange={(text, e) => {
                        setFieldValue('country', text);
                      }}
                      // eslint-disable-next-line no-unused-vars
                      onBlur={(e) => setFieldTouched('country', true)}
                    />
                  </FormGroup>
                )}
              </Field>

              <FormCheck
                type="switch"
                id="custom-switch"
                label={<b>EDIT CLIENT</b>}
                checked={checked}
                onChange={handleEditCheck}
              />

              <Button
                type="submit"
                variant="primary"
                disabled={!disabled || isSubmitting}
              >
                Submit changes
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ViewClient.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    streetNumber: PropTypes.number,
    poNumber: PropTypes.number,
    country: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
  }).isRequired,
};

ViewClient.defaultProps = {
  show: false,
};

export default ViewClient;
