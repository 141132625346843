import { gql } from '@apollo/client';

const USERS = gql`
  query getUsers {
    getUsers {
      id
      username
      password
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

export default USERS;
