import { gql } from '@apollo/client';

const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserMutation($updateUserInput2: UpdateUserInput!) {
    updateUser(input: $updateUserInput2) {
      id
      password
    }
  }
`;

export default UPDATE_USER_PASSWORD;
