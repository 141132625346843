import { gql } from '@apollo/client';

const UPDATE_CLIENT = gql`
  mutation updateClient($updateClientInput: UpdateClientInput!) {
    updateClient(input: $updateClientInput) {
      id
      name
      country
      poNumber
      city
      street
      streetNumber
      createdAt
      updatedAt
    }
  }
`;

export default UPDATE_CLIENT;
