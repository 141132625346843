import React, { useState } from 'react';

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Loader from 'react-loader-spinner';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Formik, Field, Form } from 'formik';
import { useQuery, useMutation } from '@apollo/client';

import ClientList from '../ClientList';

import CLIENTS from '../../../queries/getClients';
import NEW_CLIENT from '../../../mutations/createClient';
import countries from '../../../constants/countries';

import './index.css';

const ClientForm = () => {
  const [show, setShow] = useState(false);
  const { data: dataClients, loading } = useQuery(CLIENTS);

  // eslint-disable-next-line no-unused-vars
  const [newClient, { data: newDataClient, error }] = useMutation(NEW_CLIENT, {
    update: (cache, result) => {
      const data = cache.readQuery({ query: CLIENTS });

      cache.writeQuery({
        query: CLIENTS,
        data: {
          getClients: [result.data.createClient, ...data.getClients],
        },
      });
    },
  });

  const handleViewClientModal = () => {
    setShow((prevState) => !prevState);
  };

  if (loading) {
    return (
      <Loader
        className="loader"
        type="Oval"
        color="black"
        height={75}
        width={75}
      />
    );
  }

  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      {dataClients && dataClients.getClients ? (
        <ClientList clients={dataClients.getClients} />
      ) : (
        <Loader
          className="loader"
          type="Oval"
          color="black"
          height={75}
          width={75}
        />
      )}

      <Button variant="primary" onClick={handleViewClientModal}>
        Client form
      </Button>

      <Modal show={show} onHide={handleViewClientModal}>
        <Modal.Header closeButton className="dark">
          <Modal.Title>Create new client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark">
          <Formik
            initialValues={{
              name: '',
              city: '',
              street: '',
              streetNum: '',
              poNum: '',
              country: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              newClient({
                variables: {
                  createClientInput: {
                    name: values.name,
                    city: values.city,
                    street: values.street,
                    streetNumber: values.streetNum,
                    poNumber: values.poNum,
                    country:
                      typeof values.country === 'object'
                        ? values.country[0]
                        : values.country,
                  },
                },
              });

              setSubmitting(false);

              handleViewClientModal();
            }}
          >
            {({ isSubmitting, setFieldValue, setFieldTouched }) => (
              <Form>
                <Field name="name">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="name">
                      <FormLabel>Name:</FormLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Enter name"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="city">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="city">
                      <FormLabel>City:</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Enter city"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="street">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="street">
                      <FormLabel>Street:</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Enter street"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="streetNum">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="streetNum">
                      <FormLabel>Street number:</FormLabel>
                      <FormControl
                        type="number"
                        placeholder="Enter street number"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="poNum">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="poNum">
                      <FormLabel>PO Number:</FormLabel>
                      <FormControl
                        type="number"
                        placeholder="Enter PO number"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="country">
                  {() => (
                    <FormGroup className="mb-3" controlId="country">
                      <FormLabel>Country:</FormLabel>
                      <Typeahead
                        id="country-select"
                        labelKey="name"
                        options={countries.map((element) => `${element}`)}
                        placeholder="Select/Enter country"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('country', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('country', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('country', true)}
                      />
                    </FormGroup>
                  )}
                </Field>

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Submit'}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientForm;
