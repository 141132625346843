import { gql } from '@apollo/client';

const LOGIN_USER = gql`
  mutation loginUser($loginUserInput: LoginUserInput!) {
    loginUser(input: $loginUserInput) {
      accessToken
      refreshToken
    }
  }
`;

export default LOGIN_USER;
