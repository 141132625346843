import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import Container from 'react-bootstrap/Container';

import jsonwebtoken from 'jsonwebtoken';

import { isStaffOps } from '../../utils/auth';

import './index.css';

const NavigationBar = () => {
  const isNavBarCollapsed = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const accessToken = localStorage.getItem('accessToken');
  const decodedToken = jsonwebtoken.decode(accessToken);

  const handleLogOutButton = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    window.location.href = '/';
  };

  // AM -> create projects
  // ACC -> list projects
  // SO -> all

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="#home" />
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="justify-content-end"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid">
            {isStaffOps() && (
              <>
                <NavLink
                  className="nav-link"
                  activeStyle={{ fontWeight: 'bold' }}
                  exact
                  to="/staffops"
                >
                  StaffOps Dashboard
                </NavLink>
                <NavLink
                  className="nav-link"
                  activeStyle={{ fontWeight: 'bold' }}
                  exact
                  to="/clients"
                >
                  Clients List
                </NavLink>
              </>
            )}
            <NavLink
              className="nav-link"
              activeStyle={{ fontWeight: 'bold' }}
              exact
              to="/projects"
            >
              Projects List
            </NavLink>
            <NavLink
              className="nav-link"
              activeStyle={{ fontWeight: 'bold' }}
              exact
              to="/settings"
            >
              Settings
            </NavLink>
            <NavItem
              className={`${isNavBarCollapsed ? 'ms-auto' : ''} nav-link`}
              style={{ color: 'black' }}
            >
              Hello {decodedToken.userUsername}! To log out, click{' '}
              <button
                type="button"
                className="logout"
                onClick={handleLogOutButton}
              >
                here
              </button>
              .
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

NavigationBar.propTypes = {
  token: PropTypes.shape({
    userUsername: PropTypes.string,
  }),
};

NavigationBar.defaultProps = {
  token: null,
};

export default NavigationBar;
