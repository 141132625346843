import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route, useHistory } from 'react-router-dom';

import NavigationBar from '../NavigationBar';
import { isStaffOps } from '../../utils/auth';

// eslint-disable-next-line import/no-cycle
// import NavigationBar from '../NavigationBar';

const StaffOpsRoute = ({ children, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  if (isStaffOps()) {
    return (
      <Route {...rest}>
        <NavigationBar />
        {children}
      </Route>
    );
  }

  return <Redirect to="/" />;
};

StaffOpsRoute.propTypes = {
  children: PropTypes.node,
};

StaffOpsRoute.defaultProps = {
  children: null,
};

export default StaffOpsRoute;
