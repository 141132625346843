import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';

import './index.css';

const TablePagination = ({
  firstPage,
  canPrev,
  prevPage,
  nextPage,
  canNext,
  gotoLast,
  pageIndex,
  pageOptions,
  gotoPageNum,
  pageSize,
  setPageSize,
}) => (
  <div className="pagination">
    <button
      type="button"
      onClick={firstPage}
      disabled={canPrev}
      style={{ all: 'unset', marginRight: '5px', cursor: 'pointer' }}
    >
      <FontAwesomeIcon icon={faAngleDoubleLeft} />
    </button>{' '}
    <button
      type="button"
      onClick={prevPage}
      disabled={canPrev}
      style={{ all: 'unset', marginRight: '5px', cursor: 'pointer' }}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>{' '}
    <button
      type="button"
      onClick={nextPage}
      disabled={canNext}
      style={{ all: 'unset', marginRight: '5px', cursor: 'pointer' }}
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </button>{' '}
    <button
      type="button"
      onClick={gotoLast}
      disabled={canNext}
      style={{ all: 'unset', marginRight: '5px', cursor: 'pointer' }}
    >
      <FontAwesomeIcon icon={faAngleDoubleRight} />
    </button>{' '}
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
    </span>
    <span>
      {' '}
      | Go to page:{' '}
      <input
        type="number"
        defaultValue={pageIndex + 1}
        onChange={gotoPageNum}
        style={{ width: '100px' }}
      />
    </span>{' '}
    <select value={pageSize} onChange={setPageSize}>
      {[10, 20, 30, 40, 50].map((element, index) => (
        <option key={index} value={element}>
          Show {element}
        </option>
      ))}
    </select>
  </div>
);

TablePagination.propTypes = {
  firstPage: PropTypes.func.isRequired,
  canPrev: PropTypes.bool.isRequired,
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canNext: PropTypes.bool.isRequired,
  gotoLast: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  gotoPageNum: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
};

export default TablePagination;
