import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import Button from 'react-bootstrap/Button';

import TableColumnSearch from '../../TableColumnSearch';

import ClientTable from '../ClientTable';
import ViewClient from '../ViewClient';

const ClientList = ({ clients }) => {
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const data = useMemo(() => clients, [clients]);

  const handleViewClientShow = () => {
    setShow((prevState) => !prevState);
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        id: 'user',
        Cell: ({ row }) => (
          <Avatar round="5px" size="25px" name={row.original.name} />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: (row) => `${row.street} ${row.streetNumber}`,
        Cell: ({ row }) => (
          <>{`${row.original.street ? row.original.street : ''} ${
            row.original.streetNumber ? row.original.streetNumber : ''
          }`}</>
        ),
        Filter: TableColumnSearch,
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Street',
        accessor: 'street',
      },
      {
        Header: 'Street Number',
        accessor: 'streetNumber',
      },
      {
        Header: 'PO Number',
        accessor: 'poNumber',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        id: 'view',
        Cell: ({ row }) => (
          <Button
            size="sm"
            onClick={() => {
              setShow((prevState) => !prevState);
              setId(row.original.id);
            }}
          >
            View / Edit
          </Button>
        ),
      },
    ],
    []
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <ClientTable columns={columns} data={data} />
      <ViewClient
        show={show}
        onHide={handleViewClientShow}
        clients={id ? data.find((element) => element.id === id) : {}}
      />
    </>
  );
};

ClientList.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
      city: PropTypes.string,
      street: PropTypes.string,
      streetNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      poNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      country: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ).isRequired,
};

export default ClientList;
