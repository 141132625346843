import { gql } from '@apollo/client';

const CREATE_PROJECT = gql`
  mutation Mutation($createProjectInput: CreateProjectInput!) {
    createProject(input: $createProjectInput) {
      id
      client {
        id
        name
        country
        poNumber
        city
        street
        streetNumber
        createdAt
        updatedAt
      }
      invoicePaymentTerms
      billingInstructions
      billingContact {
        id
        name
        email
        phone
        createdAt
        updatedAt
      }
      engagementName
      name
      engagementManager {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      projectManager {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      engagementType
      billable
      utilizationEligible
      goToMarket
      businessUnit
      serviceArea
      projectCostcenter
      salesLead {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      startDate
      endDate
      budget
      travelAndExpenseApprover {
        id
        username
        firstName
        lastName
        email
        role {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      enableAdditionalTimeTypesNotBillable
      enableAdditionalTimeTypesOverTimePaid
      enableAdditionalTimeTypesOverTimeNoPay
      enableAdditionalTimeTypesOnCall
      enableExpenses
      expensePolicy
      psaMsaExecuted
      ndaExecuted
      sowCoCrExecuted
      resources {
        id
        user {
          id
          username
          firstName
          lastName
          email
          role {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        role
        billRate
        startDate
        endDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export default CREATE_PROJECT;
