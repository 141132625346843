/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';

import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { useFilter } from '../../../hooks';

import TablePagination from '../../TablePagination';
import TableColumnSearch from '../../TableColumnSearch';

const UserTable = ({ columns, data }) => {
  const { filterTypes } = useFilter();

  const defaultColumn = useMemo(
    () => ({
      Filter: TableColumnSearch,
    }),
    []
  );
  /* eslint-disable no-unused-vars */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, defaultColumn, filterTypes },
    useFilters,
    useSortBy,
    usePagination
  );
  /* eslint-enable no-unused-vars */

  return (
    <>
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index}>
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <div>{column.canFilter && column.render('Filter')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <TablePagination
        firstPage={() => gotoPage(0)}
        canPrev={!canPreviousPage}
        prevPage={() => previousPage()}
        nextPage={() => nextPage()}
        canNext={!canNextPage}
        gotoLast={() => gotoPage(pageCount - 1)}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        gotoPageNum={(e) => {
          const newPage = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(newPage);
        }}
        pageSize={pageSize}
        setPageSize={(e) => {
          setPageSize(Number(e.target.value));
        }}
      />
    </>
  );
};

UserTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserTable;
