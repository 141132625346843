import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import randomColor from 'randomcolor';

import ProjectTable from '../ProjectTable';
import ViewProject from '../ViewProject';

const ProjectList = ({ projects }) => {
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const data = useMemo(() => projects, [projects]);

  const handleViewProjectShow = () => {
    setShow((prevState) => !prevState);
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        id: 'color',
        Cell: () => (
          <div
            style={{
              width: '25px',
              height: '25px',
              backgroundColor: randomColor({
                luminosity: 'dark',
                format: 'rgba',
              }),
              borderRadius: '5px',
            }}
          />
        ),
      },
      {
        Header: 'Project Name',
        accessor: 'name',
      },
      {
        id: 'view',
        Cell: ({ row }) => (
          <Button
            size="sm"
            onClick={() => {
              setShow((prevState) => !prevState);
              setId(row.original.id);
            }}
          >
            View / Edit
          </Button>
        ),
      },
    ],
    []
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <ProjectTable columns={columns} data={data} />
      {data && (
        <ViewProject
          show={show}
          onHide={handleViewProjectShow}
          project={id ? data.find((element) => element.id === id) : null}
        />
      )}
    </>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ProjectList;
