import { gql } from '@apollo/client';

const ROLES = gql`
  query getRoles {
    getRoles {
      id
      name
    }
  }
`;

export default ROLES;
