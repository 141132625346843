import React from 'react';
import PropTypes from 'prop-types';

import ClientForm from './ClientForm';

const Clients = ({ accessLevel }) => <ClientForm accessLevel={accessLevel} />;

Clients.propTypes = {
  accessLevel: PropTypes.number,
};

Clients.defaultProps = {
  accessLevel: 0,
};

export default Clients;
