import { gql } from '@apollo/client';

const REFRESH_TOKENS = gql`
  mutation GetNewTokensMutation($getNewTokensInput: GetNewTokensInput!) {
    getNewTokens(input: $getNewTokensInput) {
      accessToken
      refreshToken
    }
  }
`;

export default REFRESH_TOKENS;
