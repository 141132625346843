import { gql } from '@apollo/client';

const USER = gql`
  query getUser($getUserFilter3: GetUserInput!) {
    getUser(filter: $getUserFilter3) {
      id
      username
      password
    }
  }
`;

export default USER;
